<template>
	<div class="page">

		<!-- 搜索条件表单 -->
		<el-form class="ser_form" size="small"  label-width="100px">
			<el-form-item class="el_form_item" label="运单编号">
				<el-input class="el_input" v-model="form.truck_tord_num" placeholder="货源运单编号" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="支付编号">
				<el-input class="el_input" v-model="form.payed_num" placeholder="支付编号" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="银行流水号">
				<el-input class="el_input" v-model="form.bank_ra_num" placeholder="银行流水号" clearable></el-input>
			</el-form-item>
			<el-form-item class="el_form_item" label="记录类型">
				<el-select class="el_input" v-model="form.this_pay_type" clearable>
					<el-option label="主记录" value=""></el-option>
					<el-option label="记录" value="1"></el-option>
					<el-option label="撤销的记录" value="2"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item class="el_form_item elfrom_item" label="回款时间">
				<div class="block">
				<el-date-picker
					v-model="creat_time_arr"
					type="datetimerange"
					:picker-options="pickerOptions"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期"
					align="right"
					value-format="timestamp"
				>
				</el-date-picker>
				</div>
			</el-form-item>
			<el-form-item label-width="0">
				<el-button type="primary" @click="page_ser">查询</el-button>
				<el-button type="primary" @click="ser_para_clear">清空条件</el-button>
				<!-- <el-button type="primary"  @click="set_truck_owner">设置承运商</el-button> -->
			</el-form-item>
		</el-form>
		<!-- <div class="total_info item">
			<span>共:{{list.total}}条</span>
			<span>总计:{{total}}元</span>
		</div> -->
		<!-- 表格 -->
		<div class="tab_height">
			<el-table :data="list.data" :border="true" :stripe="true" size="small" height="100%" v-loading="list.loading" >
				<!-- <el-table-column type="selection" width="39px"></el-table-column> -->
				<!-- <el-table-column label="运单id">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.id}}</div>
					</template>
				</el-table-column> -->
				<el-table-column label="运单编号" >
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.truck_tord_num}}</div>

					</template>
				</el-table-column>
				<el-table-column label="支付编号" >
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.payed_num}}</div>

					</template>
				</el-table-column>
                <el-table-column label="银行流水号">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.bank_ra_num}}</div>
					</template>
				</el-table-column>
				<el-table-column label="回款时间">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.creat_time_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="支付时间">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.creat_time_text}}</div>
					</template>
				</el-table-column>
				<el-table-column label="司机收款（含额外服务费）">
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.much}}元</div>		
					</template>
				</el-table-column>

				<!-- 操作行 -->
			</el-table>
		</div>
		<el-backtop
		target=".page"
		:bottom="100"
		:visibility-height="50"
		></el-backtop>
		<!-- 分页 -->
		<el-pagination 
			class="margin-top-xl" 
			@size-change="page_size_change" 
			@current-change="page_current_change" 
			:current-page="page.p" 
			:page-sizes="[10, 100, 200, 500]" 
			:page-size="page.num" 
			:total="list.total*1" 
			layout="total, sizes,prev, pager, next, jumper" 
		></el-pagination>
	</div>
</template>

<script>
	import {mapState} from 'vuex'
	import { Loading } from 'element-ui';
	export default {
		components:{
		},
		data() {
			return {
				creat_time_arr:'',
				pickerOptions: {
					shortcuts: [
					{
						text: "最近一周",
						onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
						picker.$emit("pick", [start, end]);
						},
					},
					{
						text: "最近一个月",
						onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
						picker.$emit("pick", [start, end]);
						},
					},
					{
						text: "最近三个月",
						onClick(picker) {
						const end = new Date();
						const start = new Date();
						start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
						picker.$emit("pick", [start, end]);
						},
					},
					],
				},
				//搜索条件
				form: {				
					truck_tord_num:'',//货源编号
                    start_time:'', //回款时间-起
                    end_time:'',//回款时间-止
					type:'0',//0主记录 1分记录 2撤销的记录 默认只查询主记录 。 查分记录时必传
					bank_ra_num:'',//银行流水号
					payed_num:'',//支付记录编号
				},
				//表格数据
				list: {
					loading: false,//loading特效
					data: [],//列表数据
					total: 0,//列表总记录数
				},

				//页码信息
				page: {
					need_num:1,
					num: 10,
					p: 1,
				},

				
				
			}
		},
		created() {

			//读取货源
			this.get_page_data()
		},
		computed:{
			...mapState(['logink_aim_list'])
		},
		methods: {

			//搜索
			page_ser(){
				//读取第一页
				this.page.p=1
				if (this.creat_time_arr) {
					this.form.start_time = parseInt(this.creat_time_arr[0] / 1000);
					this.form.end_time= parseInt(this.creat_time_arr[1] / 1000);
				} else {
					this.form.start_time = "";
					this.form.creat_time_end = "";
				}
				//读取
				this.get_page_data()
			},
			
			//清空查询条件
			ser_para_clear(e){
				this.form={
                    truck_tord_num:'',//货源编号
                    creat_time_start:'', //申请时间-起
                    creat_time_end:'',//申请时间-止
                    pass_time_start:'',//审核时间-起
                    pass_time_end:'',//审核时间-止
				}
				this.creat_time_arr=''
				this.pass_time_arr = ''
				if(e !=1){
					//读取
					this.get_page_data()
				}
			
				
			},

			//导出搜索结果
			tord_export_open(){//打开界面

				//置入条件
				this.tord_export.rull=this.form

				//打开界面
				this.tord_export.is_show++
			},
			
			tord_export_close(){//关闭界面

				//关闭界面
				this.tord_export.is_show=0
			},

			//页宽变化
			page_size_change(num){

				//置入页码
				this.page.num=num

				//读取
				this.get_page_data()
			},

			//页码变化
			page_current_change(p){
				
				//置入页码
				this.page.p=p

				//读取
				this.get_page_data()
			},

			//获取货源列表
			get_page_data(){

				//加载中...
				if(this.list.loading){
					// return;
				}

				//置为加载中
				this.list.loading=true;
				//读取数据
				this.$my.net.req({
					take_over_control:1,
					data:{
						mod:'truck_tord_real_time',
						ctr:'pay_return_list',
						...this.form,
						...this.page
					},
					callback:(data)=>{
						if(data.code ==0){
							//加载完毕
							this.list.loading=false
							this.list.total=data.msg.max
							//预处理
							for(let item of data.msg.list){
								//申请
								item.creat_time_text=this.$my.other.totime(item.creat_time);
								//支付时间
								item.payed_time_text=this.$my.other.totime(item.payed_time);
                                // switch(item.status){
								// 	case '1':item.status_text="待审核";break;
								// 	case '2':item.status_text="审核通过" + this.$my.other.totime(item.pass_time);break;
								// 	case '2':item.status_text="审核驳回" + this.$my.other.totime(item.unpass_time);break;
								// }

							}
							//渲染
							this.list.data=data.msg.list

						}else{
							this.$my.other.msg({
								str:data.error_info,
								type:'info'
							});
							this.list.loading=false
							this.list.data=[]
						}

					}
				})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.page{
		background-color: #fff;
		padding: 20px;
		border-radius: 10px;
		height: calc(100% - 40px);
		overflow: auto;
	}
	.ser_form{
		display: flex;
		align-items: flex-end;
		flex-wrap: wrap;
	}
	.tab_height{
		height:83%;
	}
	.el_form_item{
		width: 16.6672%;
	}
	.el_input{
		width:94%
	}
	.btn_left{
		margin: 0;
	}
		.total_info {
		text-align: right;
		font-size: 12px;
		margin-bottom: 24px;
		margin-right: 1px;
		margin-top: 10px;
		color: #606266;
		
		span {
			margin-left: 20px;
		}
	}
	.elfrom_item{
		width:auto;
		margin-right:10px;
	}
	/deep/.el-button--text{
		color: #409eff !important;
	}
	.btn_delete{
		color:red !important
	}
</style>